
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Rating from "../Rating.vue";
import { Modal } from "bootstrap";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
const videoReload = ref(0);
const showSeeking = ref(false);
const currentVideo = ref();
const currentYoutube = ref();
const listData = ref();
const listSection = ref();
const videoRef = ref();
const currentChId = ref();
const showDetail = ref(true);
const showDocument = ref(false);
const showBookmark = ref(false);
const listDocument = ref();
const showYoutube = ref(false);
const showVideo = ref(false);
const currentUpdate = ref({
  sectionId: "",
  chapterId: "",
  flgSuccess: false,
});
const currentDuration = ref(0);
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "mandatory-learn",
  components: {
    Rating,
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      rateId: null,
      rate1: 0,
      rate2: 0,
      rate3: 0,
      rate4: 0,
      showModal: false,
      isFirefox: false,
    };
  },
  setup() {
    /* eslint-disable */

    const router = useRouter();
    const route = useRoute();
    onMounted(() => {
      getDataList();
      // getDocuments();
      // getBookmark();
    });
    watch(
      () => route.path,
      () => {
        nextTick(() => {
          getDataList();
        });
      }
    );
    const getDataList = async () => {
      const getSyllabus = await axios.get(
        process.env.VUE_APP_API_URL + "/getSyllabus?id=" + route.params.sbId,
        {
          headers: {
            token: localStorage.getItem("id_token")
          },
        }
      );
      showVideo.value = false;
      axios
        .get(
          process.env.VUE_APP_API_URL + "/getCourse?id=" + route.params.cId,
          // "&categories=" +
          // categories.value
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data.content[0]);
          listData.value = response.data.data.content[0];
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          getChapterList();
          setCurrentPageBreadcrumbs(listData.value.title, [
            getSyllabus.data.data.content[0].name,
          ]);
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const getChapterList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getCourseSection?courseId=" +
            route.params.cId,
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data.content);
          listSection.value = response.data.data.content;
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          // videoExam(listData.value.chapter.chapterId, false);
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const getDocuments = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/courses/" +
            route.params.id +
            "/documents",
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          listDocument.value = response.data.result[0].downloadFiles;
          // console.log(response.data.result[0].downloadFiles);
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const getBookmark = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/courses/" +
            route.params.id +
            "/bookmark",
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          // console.log(response.data.result);
          if (response.data.result.length > 0) {
            showBookmark.value = true;
          } else {
            showBookmark.value = false;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const videoExam = async (secId, chId, autoPlay) => {
      // console.log(videoExam);
      currentUpdate.value.sectionId = secId;
      currentUpdate.value.chapterId = chId;
      showVideo.value = true;
      showYoutube.value = false;
      currentChId.value = chId;
      let flgSuccess = false;
      for (const loopSection of listSection.value) {
        if (secId == loopSection.id) {
          for (const loopChapter of loopSection.chapters) {
            if (loopChapter.chapterId == chId) {
              // console.log(loopChapter);
              currentVideo.value =
                process.env.VUE_APP_IMG_URL + loopChapter.videoPath;
              flgSuccess = loopChapter.flgSuccess;
              currentUpdate.value.flgSuccess = loopChapter.flgSuccess;
              showSeeking.value = loopChapter.flgSuccess;
              videoReload.value += 1;
            }
          }
        }
      }
      if (flgSuccess != true) {
        let currentTime = await axios.get(
          process.env.VUE_APP_API_URL +
            `/getCourseVideoProgress?courseId=${route.params.cId}&sectionId=${secId}&chapterId=${chId}`,
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        );
        if (currentTime.data.data.length > 0) {
          videoRef.value.currentTime = currentTime.data.data[0].elapsed;
        }
      }
      if (autoPlay == true ) {
        setTimeout(() => {
          videoRef.value.play();
        }, 1000);
      }
    };
    const getExam = (secId, chId, exId, pretest) => {
      console.log(pretest);
      Swal.fire({
        title: "แบบทดสอบก่อนเรียน",
        text:
          "ระบบจะจับเวลาและบันทึกการทำข้อสอบทันที เมื่อคุณกดปุ่มเริ่มทำข้อสอบ",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#323563",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
        confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
        // cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem("reloaded", "1");
          router.push({
            name: "mandatory-exam",
            params: {
              sbId: route.params.sbId,
              cId: route.params.cId,
              secId: secId,
              chId: chId,
              exId: exId,
              type: pretest == true? 'pretest' : "posttest" ,
            },
          });
        }
      });
    };
    // const postExam = (chId, exId) => {
    //   Swal.fire({
    //     title:
    //       "ระบบจะจับเวลาและบันทึกการทำข้อสอบทันที เมื่อคุณกดปุ่มเริ่มทำข้อสอบ",
    //     text:
    //       "เกณฑ์การตัดสิน : คุณต้องได้คะแนนสอบมากกว่า 60% และทำข้อสอบได้ไม่เกิน 3 ครั้ง หากไม่ผ่าน ความคืบหน้าในบทเรียนนั้นจะถูกรีเซ็ตเป็น 0",
    //     icon: "warning",
    //     showCancelButton: false,
    //     confirmButtonColor: "#323563",
    //     cancelButtonColor: "#d33",
    //     allowOutsideClick: false,
    //     confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
    //     // cancelButtonText: "ยกเลิก",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       router.push({
    //         name: "voluntary-exam",
    //         params: {
    //           cId: route.params.id,
    //           chId: chId,
    //           exId: exId,
    //           type: "posttest",
    //         },
    //       });
    //     }
    //   });
    // };
    const detail = () => {
      showDetail.value = true;
      showDocument.value = false;
    };
    const document = () => {
      showDetail.value = false;
      showDocument.value = true;
    };
    const bookmark = () => {
      const formData = new FormData();
      formData.append("syllabusId", "-1");
      formData.append(
        "bookmark",
        showBookmark.value == true ? "false" : "true"
      );
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "/api/v1/courses/" +
            route.params.id +
            "/chapter/" +
            currentChId.value +
            "/bookmark",
          formData,
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((res) => {
          getBookmark();
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const share = (social) => {
      const url = new URL(window.location.href);
      if (social == "facebook") {
        window.open(
          "http://facebook.com/sharer/sharer.php?u=" +
            encodeURIComponent(url.href),
          "",
          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        );
      } else if (social == "twitter") {
        window.open(
          "http://www.twitter.com/share?url=" + encodeURIComponent(url.href),
          "",
          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        );
      } else {
        window.open(
          "https://social-plugins.line.me/lineit/share?url=" +
            encodeURIComponent(url.href),
          "",
          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        );
      }
    };
    const openFile = (secId, chId, file, type) => {
      currentUpdate.value.sectionId = secId;
      currentUpdate.value.chapterId = chId;
      if (type == "youtube") {
        showVideo.value = false;
        showYoutube.value = true;
        // https://www.youtube.com/embed/
        let idYoutub = file.split("v=")[1];
        currentYoutube.value = "https://www.youtube.com/embed/" + idYoutub;
      } else {
        window.open(process.env.VUE_APP_IMG_URL + file, "_blank");
      }
    };
    const updateProgress = async () => {
      const formData = new FormData();
      if (listData && currentUpdate.value.flgSuccess == false) {
        formData.append("courseId", route.params.cId + "");
        formData.append("sectionId", currentUpdate.value.sectionId);
        formData.append("chapterId", currentUpdate.value.chapterId);
        // console.log("update");
        await axios
          .post(process.env.VUE_APP_API_URL + "/updateProgress", formData, {
            headers: {
              token: localStorage.getItem("id_token")
            },
          })
          .then(async (res) => {
            if (res.data.code == 200) {
              const getCourseRegister = await axios.get(
                process.env.VUE_APP_API_URL +
                  "/getCourseRegister?userId=" +
                  localStorage.getItem("u_id") +
                  "&courseId=" +
                  route.params.cId,
                {
                  headers: {
                    token: localStorage.getItem("id_token")
                  },
                }
              );
              if ( getCourseRegister.data.data.content.length > 0 && 
                getCourseRegister.data.data.content[0].progressUpdate == 100
              ) {
                router.go(0);
              } else {
                getDataList();
              }
            } else {
              if (res.data.message != "คอร์สนี้ท่านอยู่ในสถานะจบหลักสูตรแล้ว") {
                Swal.fire({
                  title: "แจ้งเตือนข้อผิดพลาด",
                  text: res.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                  customClass: {
                    confirmButton: "btn fw-semobold btn-light-danger",
                  },
                });
              }
            }
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text: error.response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          });
      }
    };
    const seekingDisable = (event) => {
      // console.log(event);
    };
    return {
      getDataList,
      listData,
      listSection,
      getChapterList,
      getBookmark,
      getDocuments,
      videoRef,
      getExam,
      videoExam,
      currentChId,
      showDetail,
      showDocument,
      showBookmark,
      detail,
      document,
      bookmark,
      listDocument,
      share,
      currentVideo,
      openFile,
      showYoutube,
      currentYoutube,
      showVideo,
      updateProgress,
      currentUpdate,
      currentDuration,
      seekingDisable,
      videoReload,
      showSeeking,
    };
  },
  async mounted() {
    this.isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
    const getCourseRegister = await axios.get(
      process.env.VUE_APP_API_URL +
        "/getCourseRegister?userId=" +
        localStorage.getItem("u_id") +
        "&courseId=" +
        this.$route.params.cId,
      {
        headers: {
          token: localStorage.getItem("id_token")
        },
      }
    );
    if ( getCourseRegister.data.data.content.length > 0 && 
      getCourseRegister.data.data.content[0].rate == null &&
      getCourseRegister.data.data.content[0].progressUpdate == 100
    ) {
      Swal.fire({
        title: "ยินดีด้วย!",
        text: "คุณได้เรียนจบหลักสูตรแล้ว",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "ตกลง",
        showCancelButton: true,
        cancelButtonText: "ให้คะแนน",
        customClass: {
          confirmButton: "btn btn-swal1",
          cancelButton: "btn btn-swal2",
        },
      }).then(async (res) => {
        if (res.isConfirmed != true) {
          this.clickScore(getCourseRegister.data.data.content[0].id);
        }
      });
    }
  },
  methods: {
    onPlay() {
      // axios
      //   .post(
      //     process.env.VUE_APP_API_URL +
      //       "/api/v1/courses/" +
      //       this.$route.params.id +
      //       "/chapters/" +
      //       currentChId.value +
      //       "/progress/content/exam/0/score/0",
      //     {},
      //     {
      //       headers: headers,
      //     }
      //   )
      //   .then((res) => {
      // console.log(res);
      //   })
      //   .catch((error) => {
      // console.log(error);
      //   });
    },
    async logDuration() {
      let flgSuccess = false;
      for (const loopSection of listSection.value) {
        if (currentUpdate.value.sectionId == loopSection.id) {
          for (const loopChapter of loopSection.chapters) {
            if (loopChapter.chapterId == currentUpdate.value.chapterId) {
              flgSuccess = loopChapter.flgSuccess;
            }
          }
        }
      }
      const data = {
        duration: videoRef.value.duration,
        elapsed: videoRef.value.currentTime,
        remaining: videoRef.value.duration - videoRef.value.currentTime,
        scoreAverage:
          (videoRef.value.currentTime / videoRef.value.duration) * 100,
      };
      const formData = new FormData();
      formData.append("courseId", this.$route.params.cId + "");
      formData.append("sectionId", currentUpdate.value.sectionId);
      formData.append("chapterId", currentUpdate.value.chapterId);
      formData.append("duration", data.duration);
      formData.append("elapsed", data.elapsed);
      if (flgSuccess == false) {
        // console.log("update");
        await axios
          .post(
            process.env.VUE_APP_API_URL + "/updateVideoProgress",
            formData,
            {
              headers: {token: localStorage.getItem("id_token")},
            }
          )
          .then((res) => {
            // console.log(res);
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text: error.response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          });
      }
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
    handleData1(data) {
      this.rate1 = data;
    },
    handleData2(data) {
      this.rate2 = data;
    },
    handleData3(data) {
      this.rate3 = data;
    },
    handleData4(data) {
      this.rate4 = data;
    },
    async sentScore() {
      const formData = new FormData();
      formData.append("id", this.rateId + "");
      formData.append("rate1", this.rate1 + "");
      formData.append("rate2", this.rate2 + "");
      formData.append("rate3", this.rate3 + "");
      formData.append("rate4", this.rate4 + "");
      formData.append("remark", "");

      await axios
        .post(process.env.VUE_APP_API_URL + "/updateCourseRate", formData, {
          headers: { token: localStorage.getItem("id_token") },
        })
        .then((res) => {
          if (res.data.code == 200) {
            Swal.fire({
              title: "บันทึกรายการสำเร็จ",
              text: "รายการข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(() => {
              this.$router.go(0);
            });
          } else {
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text: res.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "แจ้งเตือนข้อผิดพลาด",
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    },
    async clickScore(rateId) {
      this.rateId = rateId;
      this.rate1 = 0;
      this.rate2 = 0;
      this.rate3 = 0;
      this.rate4 = 0;
      const modal_score = document.getElementById("kt_modal_score")!;
      const modal = new Modal(modal_score);
      modal.show();
      modal.hide();
    },
    checkBeforSuccess(indexS, indexC) {
      console.log("checkBeforSuccess : " + indexS, indexC);
      if (indexS > 0) {
        if (indexC > 0) {
          if (indexC == 0) {
            return listSection.value[indexS - 1].chapters[
              listSection.value[indexS - 1].chapters.length - 1
            ].flgSuccess == false
              ? true
              : false;
          } else {
            return listSection.value[indexS].chapters[
              listSection.value[indexS].chapters.length - 1
            ].flgSuccess == false
              ? true
              : false;
          }
        } else {
          return listSection.value[indexS - 1].chapters[
            listSection.value[indexS - 1].chapters.length - 1
          ].flgSuccess == false
            ? true
            : false;
        }
      } else {
        if (indexC > 0) {
          return listSection.value[indexS].chapters[indexC - 1].flgSuccess ==
            false
            ? true
            : false;
        } else {
          return false;
        }
      }
    },
  },
});
